export default {
    data() {
        return {
            newPwd: '',
            confirm_pwd: '',
            fill_name: '',
            newMobile: '',
            newCode: ''
        }
    },
    created() {

    },
    methods: {
        editInfoByMobileCode: function() {
            this.$post('/api/Account/editInfoByMobileCode', {
                pwd: this.newPwd,
                confirm_pwd: this.confirm_pwd,
                fill_name: this.fill_name,
                mobile: this.newMobile,
                code: this.newCode,
                token: true
            }).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.$router.push('/index');
                } else {
                    return this.$message(res.msg)
                }
            }).finally(() => {})
        }
    }
}