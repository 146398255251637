export default {
    data() {
        return {
            second: 60,
            cutdown: false,
            hint: "发送验证码",
            mobile: "",
            event: "mobilelogin",
            code: "",
            stop: false,
        };
    },
    created() {},
    methods: {
        getCode: function() {
            if (this.cutdown || this.mobile == '') {
                return this.$message('请输入正确手机号');
            }
            this.codeData()
        },
        getCodeChange: function() {
            console.log(this.newMobile)
            if (this.cutdown || this.newMobile == '') {
                return this.$message('请输入正确手机号');
            }
            this.codeData()
        },
        codeData() {
            this.$post("/api/index/sendSms", { mobile: this.loginType == 3 ? this.newMobile : this.mobile })
                .then((res) => {
                    if (res.code == 200) {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.countDown();
                    } else if (res.code == 0) {
                        return this.$message(res.msg);
                    }

                })
                .finally(() => {});
        },
        countDown: function() {
            this.cutdown = true;
            let setInt = setInterval(() => {
                this.second--;
                this.hint = `${this.second}秒后重新发送`;
                if (this.second == 0 || this.stop) {
                    clearInterval(setInt);
                    this.hint = "发送验证码";
                    this.second = 60;
                    this.cutdown = false;
                    this.stop = false
                }
            }, 1000);
        },
    },
};