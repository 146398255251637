export default {
    data() {
        return {
            account: '',
            pwd: '',
        }
    },
    created() {

    },
    methods: {
        accountLogin: function() {
            this.$post('/api/Account/accountLogin', {
                account: this.account,
                pwd: this.pwd,
            }).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.setUserInfo(res.data)
                    this.setStorage(res.data)
                    if (res.data.last_time) {
                        let url = (window.location.hostname == 'localhost' || window.location.hostname == 'hainan.nengkongyun.com') ? "https://hainan.nengkongyun.com/" : "https://nengkongyun.com/"
                        // window.location.href = url+"special/";
                        if (res.data.class == 1) {
                            // window.location.href = 'http://localhost:8081/government/index'
                            window.location.href = url+'government/index'
                        } else if (res.data.class == 2) {
                            // window.location.href = 'http://localhost:8081/enterprise/index'
                            window.location.href = url+'enterprise/index'
                        } else if (res.data.class == 4) {
                            // window.location.href = 'http://localhost:8081/special/index'
                            window.location.href = url+'special/index'
                        }
                        // window.location.href = 'http://localhost:8081/government/index'
                        // (window.location.hostname == 'localhost' || window.location.hostname == 'hainan.nengkongyun.com') ? "https://hainan.nengkongyun.com/" : "https://nengkongyun.com/"
                    } else {
                        this.loginType = 3;
                        this.stop = true
                        setTimeout(() => {
                            this.stop = false
                        }, 2000);
                    }
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {})
        },
        codeLogin: function() {
            this.$post('/api/Account/accountMobileLogin', {
                code: this.code,
                mobile: this.mobile
            }).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.setUserInfo(res.data)
                    this.setStorage(res.data)
                    if (res.data.last_time) {
                        this.$router.push('/index');
                    } else {
                        this.loginType = 3;
                        this.stop = true
                        setTimeout(() => {
                            this.stop = false
                        }, 2000);
                    }
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {})
        },
        setStorage: function(info) {
            if(info.class == 2){
                localStorage.setItem('user', JSON.stringify(info));
                localStorage.setItem('token', info.token);
            }else{
                localStorage.setItem('guser', JSON.stringify(info));
                localStorage.setItem('gtoken', info.token);
            }
        }
    }
}