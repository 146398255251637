export default {
    data() {
        return {
            hint: '欢迎登录',
            loginType: 1,
            content: '',
            open: false
        }
    },
    created() {
        this.getConfig()
    },
    methods: {
        changeLogin: function(type) {
            this.loginType = type;
        },
        handleKeepOnRecord: function() {
            window.open('https://beian.miit.gov.cn')
        },
        toLiuniu: function() {
            window.open('https://www.liuniukeji.com')
        },
        getConfig () {
            let data= {
                name:'remarks'
            }
            this.$post('/api/Index/getConfig',data).then(res=>{
                console.log(res)
                this.content = res.data.platform_introduction
                // this.remarks = res.data.warning
            })
        }
    }
}